.skill-badge {
  text-align: center;
  padding: 1rem;
}
@media only screen and (max-width: 820px) {
  .skill-badge {
    font-size: 10px;
    padding: 0 10px;
  }
}
.skill-badge img {
  width: 75px;
  height: 75px;
  object-fit: contain;
}
@media only screen and (max-width: 820px) {
  .skill-badge img {
    width: 35px;
    height: 35px;
  }
}
.skill-badge p {
  padding-top: 5px;
}

