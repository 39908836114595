@import "../../vars";

#About {
    height: fit-content;
    margin-bottom: 10rem;


    #aboutContent {
        padding: 1rem 4rem;
        display: flex;
        justify-content: space-around;

        @media #{$media-mobile} {
            padding: 10px;
        }


        #aboutMe {
            width: 53%;
            font-size: 2rem;
            line-height: 3rem;
            text-align: left;
            color: #EEE;

            @media #{$media-mobile} {
                font-size: 16px;
                width: 100%;
            }
        }

        #bbimg {
            width: 30%;
            object-fit: cover;
        }

        .code {
            color: #CCC;
            font-family: monospace;
            background: #333;
        }
        .code.list {
            &::before { content: '{'; color: #dd7878; }
            &::after { content: '}'; color: #dd7878; }
        }

        .string{
            color: #a6e3a1;
            &::before { content: '"'; }
            &::after { content: '"'; }
        }
    }

}