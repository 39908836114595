@media only screen and (max-width: 820px) {
  .project-button {
    display: none;
  }
}
.project-button a {
  text-decoration: none;
}
.project-button a.disabled {
  pointer-events: none;
}
.project-button button {
  width: 75px;
  height: 35px;
  color: #101010;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

