#About {
  height: fit-content;
  margin-bottom: 10rem;
}
#About #aboutContent {
  padding: 1rem 4rem;
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 820px) {
  #About #aboutContent {
    padding: 10px;
  }
}
#About #aboutContent #aboutMe {
  width: 53%;
  font-size: 2rem;
  line-height: 3rem;
  text-align: left;
  color: #EEE;
}
@media only screen and (max-width: 820px) {
  #About #aboutContent #aboutMe {
    font-size: 16px;
    width: 100%;
  }
}
#About #aboutContent #bbimg {
  width: 30%;
  object-fit: cover;
}
#About #aboutContent .code {
  color: #CCC;
  font-family: monospace;
  background: #333;
}
#About #aboutContent .code.list::before {
  content: "{";
  color: #dd7878;
}
#About #aboutContent .code.list::after {
  content: "}";
  color: #dd7878;
}
#About #aboutContent .string {
  color: #a6e3a1;
}
#About #aboutContent .string::before {
  content: '"';
}
#About #aboutContent .string::after {
  content: '"';
}

