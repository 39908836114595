#main-nav {
  z-index: 100;
  margin: 0;
  padding: 1em 0;
  margin-bottom: 6rem;
  width: 100%;
  background-color: #1b252e;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
#main-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 1%;
}
#main-nav ul.social-links {
  padding-right: 1%;
}
@media only screen and (max-width: 820px) {
  #main-nav ul.social-links {
    display: none;
  }
}
#main-nav ul li {
  display: inline;
}
#main-nav ul li a {
  margin: 10px;
  text-decoration: none;
  color: white;
}
#main-nav ul li a .active, #main-nav ul li a:hover {
  color: #1FAE92;
}

