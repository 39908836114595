@import "../../vars";

.footer {
    margin: 0;
    margin-top: 20px;
    padding: 20px 0;
    width: 100%;
    height: 100%;
    background-color: $nav-color;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        margin: 0;
        margin-top: 10px;

        
    @media #{$media-mobile} {
        font-size: 10px;
      }
    }
}