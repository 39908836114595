* {
  font-family: "Roboto", sans-serif;
  color: white;
  padding: 0;
  margin: 0;
}

body {
  background-color: #2a2e36;
}

.snap-to {
  scroll-margin-top: 5.2rem;
}

p.comment {
  margin: 30px 10px 2px;
  color: #7f858f;
  font-weight: bold;
}

div:has(*:is(input, textarea):required) > label::after {
  content: " *";
  color: red;
}

