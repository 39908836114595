.footer {
  margin: 0;
  margin-top: 20px;
  padding: 20px 0;
  width: 100%;
  height: 100%;
  background-color: #1b252e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer p {
  margin: 0;
  margin-top: 10px;
}
@media only screen and (max-width: 820px) {
  .footer p {
    font-size: 10px;
  }
}

