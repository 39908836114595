@media only screen and (max-width: 820px) {
  #Contact div {
    display: inline;
  }
  #Contact div div {
    margin: 10px 0;
    padding: 0;
  }
}
@media only screen and (max-width: 820px) {
  #Contact #formWrapper {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
#Contact input, #Contact textarea, #Contact button {
  margin: 0.5rem 0;
}
@media only screen and (max-width: 820px) {
  #Contact input, #Contact textarea, #Contact button {
    margin: 0;
    margin-bottom: 15px;
    width: 100%;
  }
}
#Contact textarea {
  height: 5rem;
}

