#ProjectThumbnail {
  width: 500px;
  height: 325px;
  margin: 20px 5px;
  filter: drop-shadow(0px 6px 3px black);
  cursor: pointer;
}
@media only screen and (max-width: 820px) {
  #ProjectThumbnail {
    width: 80%;
    height: 52vw;
    margin: 1rem;
  }
}
#ProjectThumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#ProjectThumbnail img:hover {
  transform: scale(1.025);
}
@media only screen and (max-width: 820px) {
  #ProjectThumbnail img:hover {
    transform: none;
  }
}
#ProjectThumbnail p#thumbnailTitle {
  position: relative;
  padding: 1rem 1px;
  left: -1px;
  bottom: 3rem;
  width: 100.5%;
  transform: scale(1.025);
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  text-justify: center;
  pointer-events: none;
}
@media only screen and (max-width: 820px) {
  #ProjectThumbnail p#thumbnailTitle {
    font-size: 16px;
    padding: 5px 0;
    left: 3px;
    width: 98%;
    bottom: 2rem;
  }
}

#ProjectPopup {
  width: 65vw;
  height: 75vh;
  background-color: #2c2c2c;
  filter: drop-shadow(0px 6px 3px black);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
@media only screen and (max-width: 820px) {
  #ProjectPopup {
    width: 70vw;
    height: 70vh;
  }
}
#ProjectPopup .no-padding {
  padding: 0;
}
#ProjectPopup img {
  aspect-ratio: 700/525;
  width: 100%;
  object-fit: cover;
}
#ProjectPopup h2 {
  margin: 1rem;
  font-weight: normal;
}
#ProjectPopup #popupContent {
  height: 100%;
}
#ProjectPopup #popupContent div {
  height: 100%;
}
@media only screen and (max-width: 820px) {
  #ProjectPopup #popupContent div {
    width: 90%;
    height: fit-content;
    padding: 5% 5% 0;
  }
}
@media only screen and (max-width: 820px) {
  #ProjectPopup #popupContent {
    display: static;
  }
  #ProjectPopup #popupContent #projectPopupContent {
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
  }
  #ProjectPopup #popupContent #projectPopupContent div {
    margin: 0;
  }
  #ProjectPopup #popupContent #projectPopupContent #title {
    font-size: larger;
  }
  #ProjectPopup #popupContent #projectPopupContent #projectTags {
    padding: 0;
    height: fit-content;
  }
  #ProjectPopup #popupContent #projectPopupContent #projectTags h5 {
    font-size: medium;
  }
  #ProjectPopup #popupContent #projectPopupContent #projectTags P {
    font-size: 10px;
    padding: 0;
  }
  #ProjectPopup #popupContent #projectPopupContent #aboutProject {
    height: 200px;
    padding: 0;
  }
  #ProjectPopup #popupContent #projectPopupContent #aboutProject h3 {
    font-size: medium;
  }
  #ProjectPopup #popupContent #projectPopupContent #aboutProject p {
    height: 100%;
    font-size: 10px;
  }
}
#ProjectPopup #popupContent #projectTags {
  margin: 1rem;
  height: 5rem;
  font-size: smaller;
}
#ProjectPopup #popupContent #projectTags p {
  color: #D0D0D0;
}
#ProjectPopup #popupContent #aboutProject {
  margin: 0 1em;
  height: 60%;
}
#ProjectPopup #popupContent #aboutProject div {
  height: 100%;
  overflow: auto;
}
#ProjectPopup #popupContent #buttons {
  margin: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#closePopUp {
  padding: 7px 10px;
  color: lightgray;
  text-decoration: underline;
  position: absolute;
  right: 0;
}
#closePopUp:hover {
  cursor: pointer;
}
@media only screen and (max-width: 820px) {
  #closePopUp {
    font-size: small;
  }
}

.material-symbols-outlined.visibility, .material-symbols-outlined.code {
  color: black;
}

#PopupBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  width: 100vw;
  height: 150vh;
  background-color: black;
  opacity: 0.75;
}

