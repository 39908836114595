@import "../../../vars";

.project-button {

    @media #{$media-mobile} {
        display: none;
    }
    
    a {
        text-decoration: none;
        
        &.disabled {
            pointer-events: none;
        }
    }
    
    button {
        width: 75px;
        height: 35px;
    
        color: #101010;
        font-weight: bold;
    
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}