#Home {
  height: 100vh;
  margin: auto;
}
#Home .center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
#Home #HeaderImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 101vh;
  z-index: -1;
  opacity: 0.2;
  padding: 0;
  margin: 0;
}

.typewriter {
  font-family: monospace, monospace;
  overflow: hidden;
  border-right: 0.15em solid white;
  white-space: nowrap;
  margin: 0 auto;
  width: 0;
  animation: expand 3s steps(23, end) 1s, blink-cursor 0.75s step-end infinite;
  animation-fill-mode: forwards;
}
@keyframes expand {
  from {
    width: 4px;
    padding-right: 4px;
  }
  to {
    width: calc(23ch + 4px);
    padding-right: 4px;
  }
}
@keyframes blink-cursor {
  from {
    border-right-color: transparent;
  }
  50% {
    border-right-color: white;
  }
  to {
    border-right-color: transparent;
  }
}

.social-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.social-links li {
  display: inline;
}
.social-links li a {
  margin: 10px;
  text-decoration: none;
  height: 100px;
}

#scrollDownArrow {
  position: absolute;
  top: 85vh;
  animation: pushDown 11s infinite;
  animation-delay: 5s;
}
#scrollDownArrow span {
  font-size: 5rem;
}
@keyframes pushDown {
  from {
    transform: translateY(0); /* Initial position */
  }
  3% {
    transform: translateY(15px); /* Move to the right */
  }
  5% {
    transform: translateY(0); /* Back to initial position */
  }
  8% {
    transform: translateY(15px); /* Move to the right */
  }
  10% {
    transform: translateY(0); /* Back to initial position */
  }
}

