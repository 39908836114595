@import "../../../vars";

$thumbnailZoom: scale(1.025);

#ProjectThumbnail {
    width: 500px;
    height: 325px;

    margin: 20px 5px;

    filter: drop-shadow(0px 6px 3px black);

    cursor: pointer;

    
    @media #{$media-mobile} {
        width: 80%;
        height: 52vw;
        margin: 1rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &:hover {
            transform: $thumbnailZoom;
            @media #{$media-mobile} {
                transform: none;
            }
        }
    }

    p#thumbnailTitle {
        position: relative;
        padding: 1rem 1px;
        left: -1px;
        bottom: 3rem;
        width: 100.5%;
        transform: $thumbnailZoom;
        background-color: rgba(0,0,0,.9);
        text-align: center;
        text-justify: center;
        
        pointer-events: none;

        
        @media #{$media-mobile} {
            font-size: 16px;
            padding: 5px 0;
            left: 3px;
            width: 98%;
            bottom: 2rem;
        }
    }
}

#ProjectPopup {
    width: 65vw;
    height: 75vh;
    background-color: #2c2c2c;
    filter: drop-shadow(0px 6px 3px black);

    position: fixed;
    // Good centering vvv
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);

    z-index: 1000;

    
    @media #{$media-mobile} {
        width: 70vw;
        height: 70vh;
    }

    .no-padding {
        padding: 0;
    }

    img {
    aspect-ratio: 700/525;
    //grid-column: 1;
        width: 100%;
        object-fit: cover;
    }

    h2 {
        margin: 1rem;
        //grid-column: 2;
        //grid-row: title;
        font-weight: normal;
    }

    #popupContent {
        height: 100%;

        div {
            height: 100%;
            
            @media #{$media-mobile} {
                width: 90%;
                height: fit-content;
                padding: 5% 5% 0;

            }
        }
        
        @media #{$media-mobile} {
            display: static;

            #projectPopupContent {
                padding: 0;
                margin: 0;
                width: auto;
                height: auto;
                div {
                    margin: 0;
                }
                #title {
                    font-size: larger;
                }

                #projectTags {
                    padding: 0;
                    height: fit-content;
                    h5 {
                        font-size: medium;
                    }
                    P {
                        font-size: 10px;
                        padding: 0;
                    }
                }

                #aboutProject {
                    height: 200px;
                    padding: 0;
                    h3 {
                        font-size: medium;
                    }
                    p {
                        height: 100%;
                        font-size: 10px;
                    }
                }
            }
        }
        
        #projectTags {
            //grid-column: 2;
            //grid-row: tags;

            margin: 1rem;
            height: 5rem;
            font-size: smaller;
            
            p {
                color: #D0D0D0;
            }
        }

        #aboutProject {
            margin: 0 1em;
            height: 60%;

            div {
                height: 100%;
                overflow: auto;
            }
        }

        #buttons {
            margin: 1rem;
            //grid-column: 2;
            //grid-row: buttons;

            display: flex;
            justify-content: space-around;
            align-items: center;
        }
    }
}

#closePopUp {
    padding: 7px 10px;
    color: lightgray;
    text-decoration: underline;
    position: absolute;
    right: 0;

    &:hover {
        cursor: pointer;
    }

    
    @media #{$media-mobile} {
        font-size: small;
    }
}

.material-symbols-outlined {
    &.visibility, &.code {
        color: black;

    }
}

#PopupBackground {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;

    width: 100vw;
    height: 150vh;

    background-color: black;
    opacity: 0.75;
}