#projectContainer {
  margin: 2rem 10rem;
  margin-bottom: 10rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: space-between;
}
@media only screen and (max-width: 820px) {
  #projectContainer {
    margin: auto;
    flex-flow: column;
    align-items: center;
  }
}

